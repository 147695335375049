<template>
    <div class="">
        <div class="flex justify-between gap-4 mb-2">
            <div v-if="has_checks" class="text-xs bg-gray-100 py-4 w-full px-2 text-gray-500">checks available in package</div>
            <Button v-if="has_checks" text="add check" class="inline" @click="openAddCheckModal" />
        </div>

        <div class="rounded-md relative">
            <modal-content ref="add-check-to-package" :max-width="480" name="addcheck" title="Add checks to package">
                <template #content>
                    <div class="">
                        <div v-if="unaddedChecks.length > 0" class="w-full">
                            <div class="" v-for="check in unaddedChecks" :key="check.id">
                                <div class="py-1 text-sm px-2 flex justify-between items-center border-b-indigo-600 mt-1" style="border-bottom: solid 1px #cdcaca">
                                    <div>
                                        {{ check.name }}
                                    </div>
                                    <div>
                                        <button class="bg-blue-200 hover:bg-blue-300 cursor-pointer rounded-sm px-4 py-1 text-xs" @click="addCheckToPackage(check)">add</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <div class="text-gray-600">Create a check & assign to this package</div>
                        </div>
                    </div>
                </template>
                <template #footer>
                    <div class="flex w-full"></div>
                </template>
            </modal-content>

            <div v-if="checksFetch.started" class="border max-auto flex justify-center items-center w-full h-80 rounded-lg ring-2-gray-500">
                <Loader />
            </div>

            <div v-if="!has_checks">
                <div class="empty-package--content-area bg-white border border-dashed border-gray-200 mx-auto flex justify-center items-center w-6/12 h-80 rounded-lg ring-2-gray-500">
                    <div class="flex flex-col justify-center items-center">
                        <div>This package does not have any active check assigned.</div>
                        <div class="mt-8">
                            <Button @click="openAddCheckModal" text="Assign check" />
                        </div>
                    </div>
                </div>
            </div>

            <div v-else class="check-container py-2 px-2 grid grid-cols-4 gap-3">
                <div class="flex p-3 items-center text-white-text shadow-sm border border-solid border-gray-200 hover:shadow rounded-lg text-sm justify-between" v-for="check in packageChecks" :key="check.id">
                    <p class="truncate text-gray-600">{{ check.name }}</p>
                    <button class="cursor-pointer bg-gray-200 text-xs text-gray-500 hover:bg-primary-focus hover:text-white-text w-6 h-6 flex items-center justify-center rounded-full text-center" @click="removeCheckFromPackage(check)">
                        <font-awesome-icon icon="times" class="fill-current text-current" />
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Button from "@/components/button";
import modalContent from "@/components/modal-content";
import axios from "@/axios";
import { getChecksList, getPackagesCheck } from "@/modules/checks/services.js";
import Loader from "@/components/loader";

export default {
    name: "checks",
    components: {
        Button,
        Loader,
        "modal-content": modalContent,
    },
    props: ["componentData"],
    data: () => ({
        checksFetch: {
            started: false,
            no_data: false,
            has_data: true,
            errored: false,
        },
        confirmRemovePackageFromCheck: false,
        checksData: null,
        isLoading: false,
        values: ["hello", "dad"],
        currentPage: 1,
        allChecks: [],

        packageChecks: [],
        getTotalPages: 3,
    }),
    methods: {
        async fetchAllPackageChecks() {
            const package_id = this.$route.params.packageName;
            this.packageChecks = await getPackagesCheck(package_id);
        },
        askRemoveConfirm(check) {
            check.confirmRemovePackageFromCheck = true;
            this.$set(check, "confirmRemovePackageFromCheck", true);
        },
        async addCheckToPackage(check) {
            const package_id = this.$route.params.packageName;

            const url = `/screening-package/${package_id}/check/${check.id}`;

            try {
                let {data} = await axios.post(url);
                this.$toast.success(data.message || "Check attached to package.");
                console.log(data, "..");
            } catch (error) {
                this.$toast.warning("Could not attach check to package.");
            }
            this.checks = this.checks.filter((e) => {
                return e.id != check.id;
            });
            this.packageChecks.push(check);
        },

        async removeCheckFromPackage(check) {
            const package_id = this.$route.params.packageName;
            const url = `/screening-package/${package_id}/check/${check.id}`;

            try {
                let { data } = await axios.delete(url);
                this.$toast.success(data.message || "Check removed from package.");
            } catch (error) {
                this.$toast.warning("Could not remove check from package.");
            }
            this.packageChecks = this.packageChecks.filter((e) => {
                return check.id !== e.id;
            });

            // this.checks.push(check)
        },
        openAddCheckModal() {
            this.$refs["add-check-to-package"].showModal();
        },
        async fetchPackageChecks() {},
        async fetchChecksList() {
            const tenant = this.$store.getters.getTenantId;
            let checksData = await getChecksList(tenant);
            checksData.map((el) => {
                if (el.parameters) {
                    let data = JSON.parse(el.parameters);
                    return { ...el, parameters: data.parameters };
                } else {
                    return { ...el };
                }
            });
            this.allChecks = checksData;
            // this.columns = columns;
            this.isLoading = false;
        },
    },
    computed: {
        has_checks() {
            return this.packageChecks.length >= 1;
        },
        checks() {
            return this.allChecks.map((e) => {
                return { ...e, confirmRemovePackageFromCheck: false };
            });
        },
        unaddedChecks() {
            return this.checks.filter((e) => {
                for (let i of this.packageChecks) {
                    if (i.id === e.id) {
                        return false;
                    }
                }
                return true;
            });
        },
    },
    async mounted() {
        if (!this.$store.getters.getTenantId) {
            await this.$store.dispatch("fetchTenantId");
        }
        this.isLoading = true;
        this.checksData = this.componentData;
        this.isLoading = false;
        await this.fetchChecksList();
        await this.fetchAllPackageChecks();
    },
};
</script>
<style scoped lang="scss">
::v-deep {
    .multiselect__tags {
        min-height: 0px !important;
    }
    .multiselect__content-wrapper {
        position: relative !important;
    }
    .multiselect__option {
        min-height: 0 !important;
    }
}
</style>
